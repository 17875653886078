<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="优惠券名称">
          <el-input v-model="searchData.name" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <el-button style="margin-bottom:20px;" type="primary" size="small" icon="el-icon-plus" @click="$router.push({ name: 'CouponAdd' })"
      >新增</el-button
    >
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="page_size" />
  </div>
</template>

<script>
import { getListAPI, deleteCouponAPI } from './api'
const columns = [
  {
    label: '优惠券ID',
    prop: 'id',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '优惠券名称',
    prop: 'name',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['name']}</p>
    }
  },
  {
    label: '优惠券类型',
    prop: 'coupon_type_desc',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['coupon_type_desc']}</p>
    }
  },
  {
    label: '最低消费金额（元）',
    prop: 'min_price',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['min_price']}</p>
    }
  },
  {
    label: '优惠方式',
    prop: 'reduce_price',
    minWidth: '150',
    customRender(h, row) {
      return (
        <div>
          <p v-show={row['coupon_type'] == 10}>减免金额：{row['reduce_price']}</p>
          <p v-show={row['coupon_type'] == 20}>折扣率：{row['discount']}</p>
        </div>
      )
    }
  },
  {
    label: '已发放/领取数量',
    prop: 'receive_num',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['receive_num']}</p>
    }
  },
  {
    label: '状态',
    prop: 'status',
    minWidth: '100',
    customRender(h, row) {
      return (
        <p>
          <el-tag size="small" v-show={row['status'] == 1} type="primary">
            显示
          </el-tag>
          <el-tag size="small" v-show={row['status'] == 0} type="warning">
            隐藏
          </el-tag>
        </p>
      )
    }
  },
  {
    label: '到期类型',
    prop: 'expire_type',
    minWidth: '120',
    customRender(h, row) {
      return (
        <div>
          <p v-show={row['expire_type'] == 10}>领取后生效</p>
          <p v-show={row['expire_type'] == 20}>固定时间</p>
        </div>
      )
    }
  },
  {
    label: '到期时间',
    prop: 'expire_type',
    minWidth: '250',
    customRender(h, row) {
      return (
        <div>
          <p v-show={row['expire_type'] == 10}>有效天数：{row['expire_day']}</p>
          <p v-show={row['expire_type'] == 20}>
            <p>开始时间：{row['start_time']}</p>
            <p>结束时间：{row['end_time']}</p>
          </p>
        </div>
      )
    }
  },
  {
    label: '排序',
    prop: 'sort',
    minWidth: '90',
    customRender(h, row) {
      return <p>{row['sort']}</p>
    }
  },
  {
    label: '添加时间',
    prop: 'created_at',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['created_at']}</p>
    }
  },

  {
    label: '操作',
    minWidth: '120',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            style="margin:0 10px 0 0"
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'CouponEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link underline={false} type="primary" onClick={() => this.handleDelete(row)}>
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',

  data() {
    return {
      columns,
      currentPage: 1,
      page_size: 10,
      total: 0,
      list: [{ id: 111 }],
      searchData: {
        name: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { name } = this.searchData
      let params = { page: this.currentPage, page_size: this.page_size, name }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    handleDelete({ id }) {
      this.$confirm('此操作将永久删除该优惠券, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteCouponAPI(id).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 10px;
        .el-input {
          width: 250px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
}
</style>
